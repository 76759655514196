/*
 * @Author: 陈乾
 * @Date: 2021-12-20 02:47:18
 * @LastEditors: 陈乾
 * @LastEditTime: 2021-12-20 23:28:09
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/utils/request.js
 */
/**
 * axios 过滤器 拦截器
 */

// 接口和 cas 环境配置
import axios from "axios";
import { Toast } from "vant";
import store from "../store"; //仓库
// console.log('🚀 ~ file: request.js ~ line 9 ~ store', store)
// create an axios instance
import config from "../config/index";
const service = axios.create({
  baseURL: config.baseApi2, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});
// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log("state", store.state.common.token);
    config.headers = {
      token: store.state.common.token ? store.state.common.token : "",
      Authorization: store.state.authorization,
    };
    // 请求接口开启loading
    if (!config.hideloading) {
      // loading
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
    }

    if (config.type && config.type === "form") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    Toast.clear();
    if (response.status && response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      console.log(response);
      return Promise.reject(response || "error");
    }
  },
  (error) => {
    Toast.clear();
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
