import request from "@/utils/request2.js";

//获取token接口
export function getToken(params) {
  return request({
    method: "get",
    url: "/public/app/financial/getToken",
    params,
  });
}

//获取金融产品列表
export function listFinancialBankProducts(data) {
  return request({
    method: "post",
    url: "/public/app/financial/listFinancialBankProducts",
    data,
  });
}

//获取金融产品信息详情
export function financialBankProductDetail(data) {
  return request({
    method: "post",
    url: "/public/app/financial/getFinancialBankProductDetail",
    data,
  });
}

//获取企业标签信息
export function listEnterpriseLabelInfos(data) {
  return request({
    method: "post",
    url: "/public/app/enterprise/listEnterpriseLabelInfos",
    data,
  });
}

//申请金融机构产品接口
export function applyFinancialProduct(data) {
  return request({
    method: "post",
    url: "/public/app/financial/applyFinancialProduct",
    data,
  });
}

export function getMessageDetailMark(data) {
  return request({
    method: "post",
    url: `/public/financial/dyna/getMessageDetail`,
    params: data,
  });
}

