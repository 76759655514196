<template>
  <div class="box">
    <div class="t-title">产品展示</div>
    <div class="banner">
      <img src="@/assets/images/loan/text.png" alt="" class="banner-text" />

      <div class="search">
        <input
          type="text"
          v-model="searchText"
          placeholder="请输入银行/贷款产品名称"
        />
        <div class="search-btn" @click="handleSearch()"></div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <p>
          共<span>{{ pagination.total }}</span
          >款产品
        </p>
        <div class="select">
          <van-field
            v-model="result1"
            is-link
            readonly
            name="picker"
            placeholder="点击选择标签"
            @click="showPicker1 = true"
          />
          <van-popup v-model="showPicker1" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns1"
              @confirm="onConfirm1"
              @cancel="showPicker1 = false"
            />
          </van-popup>
        </div>
        <div class="select">
          <van-field
            v-model="result2"
            is-link
            readonly
            name="picker"
            placeholder="点击选择企业"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              :columns="columns2"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
        </div>
      </div>
      <div class="wrap">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="item"
            v-for="item of listArr"
            :key="item.fpUuid"
            @click="handleClick(item)"
          >
            <div class="t-wrap">
              <!-- <img src="~@/assets/images/loan/bank.png" alt="" /> -->
              <img
                :src="
                  baseApi +
                  '/public/financial/dyna/loadFile?CMD=HTML&forceDownLoad=false&uuid=' +
                  item.fpCrfileUuid
                "
                alt="暂无图片"
              />
              <div class="info">
                <div class="col">{{ item.faProductName }}</div>
              </div>
            </div>
            <div class="b-wrap">
              <div class="detail">
                <div class="text">贷款额度</div>
                <div class="num">{{ item.faLoanAmount }}万元</div>
              </div>
              <div class="detail">
                <div class="text">担保方式</div>
                <div class="num">{{ item.faAssureMeans }}</div>
              </div>
              <div class="detail">
                <div class="text">贷款期限</div>
                <div class="num">{{ item.faLoanDeadline }}月</div>
              </div>
              <div class="detail">
                <div class="text">参考利率</div>
                <div class="num">
                  {{ item.faReferenceRateStart }}%~{{
                    item.faReferenceRateEnd
                  }}%
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken, listEnterpriseLabelInfos } from "@/api/loan.js";
import { applicationRecord } from "@/api/evaluation.js";
import { mapMutations } from "vuex";
export default {
  name: "Loan",
  data() {
    return {
      baseApi: "/prod-api",
      searchText: "",
      pagination: {
        current: 1,
        limit: 10,
        total: "",
      },
      applyArr1: "",
      applyArr2: "",
      listArr: [],
      showPicker1: false,
      showPicker2: false,
      result1: "",
      result2: "",
      columns1: [],
      columns2: [],
      labelArr: [],
      loading: false,
      finished: false,
    };
  },
  async beforeCreate() {
    await getToken({ sign: new Date().getTime() }).then((res) => {
      this.SET_AUTHORIZATION(res.data);
    });
  },
  mounted() {
    this.getApplicationRecord();
    this.getListEnterpriseLabelInfos();
  },
  methods: {
    //获取企业标签信息
    getListEnterpriseLabelInfos() {
      listEnterpriseLabelInfos({
        openId: localStorage.getItem("openid"),
        // openId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
        // openId: "oHepf6VLFDda6SKgJIvCcEJBCeb0",
      }).then((res) => {
        if (res.success) {
          this.labelArr = res.data;
          res.data.forEach((item) => {
            this.columns2.push(item.enterpriseName);
            item.bliBqmc.forEach((item2) => {
              this.columns1.push(item2);
            });
          });
          this.columns1 = [...new Set(this.columns1)];
        }
      });
    },
    onConfirm1(val) {
      this.applyArr1 = val;
      this.result1 = val;
      this.showPicker1 = false;
      this.getApplicationRecord();
    },
    onConfirm2(val) {
      let value = this.labelArr.filter((item) => item.enterpriseName == val);
      this.applyArr2 = value[0].bliBqmc;
      this.result2 = val;
      this.showPicker2 = false;
      this.getApplicationRecord();
    },
    ...mapMutations(["SET_AUTHORIZATION"]),
    getApplicationRecord() {
      applicationRecord({
        applyUsers: [this.applyArr1, ...this.applyArr2],
        page: this.pagination.current,
        limit: this.pagination.limit,
        keyword: this.searchText,
        openId: localStorage.getItem("openid"),
        // openId: "oHepf6VLFDda6SKgJIvCcEJBCeb0",
      }).then((res) => {
        if (res.success) {
          this.listArr = [...res.data];
          this.pagination.total = res.totalCount;
        }
      });
    },
    //查询
    handleSearch() {
      this.getApplicationRecord();
    },
    handleClick(val) {
      if (val.faProductScore || val.faBankScore) {
        this.$toast("您已对该产品进行过评分");
        return;
      }
      this.$router.push({
        path: "./evaluationDetail",
        query: {
          uuid: val.faProductUuid,
          faUuid: val.faUuid,
        },
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // for (let i = 0; i < 10; i++) {
        //   this.list.push(this.list.length + 1);
        // }

        // // 加载状态结束
        // this.loading = false;

        // // 数据全部加载完成
        // if (this.list.length >= 40) {
        //   this.finished = true;
        // }
        if (
          this.pagination.current * this.pagination.limit >=
          this.pagination.total
        ) {
          this.finished = true;
        } else {
          this.loading = false;
          this.pagination.current++;
          this.getApplicationRecord();
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
