import request from "@/utils/request2.js";

//获取当前账户金融产品申请记录列表
export function applicationRecord(data) {
  return request({
    method: "post",
    url: "/public/app/financial/getApplicationRecord",
    data,
  });
}

//基于产品申请记录给银行和产品进行打分
export function scoreProductAndBank(data) {
  return request({
    method: "post",
    url: "/public/app/financial/scoreProductAndBank",
    data,
  });
}
